<template>
  <div>
    <c-table
      ref="grid2"
      class="q-mt-md"
      title="LBLIMPRREQUEST"
      :columns="grid2.columns"
      :gridHeight="gridHeight"
      :data="imprs"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable"
      @linkClick="imprlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addImpr" />
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      ref="grid1"
      title="즉시조치"
      :columns="grid.columns"
      :gridHeight="gridHeight"
      :data="imprImms"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable"
      @linkClick="immlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addImprImm" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'jobStressAssessmentResultImpr',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaJobStressPlanId: '',
      }),
    },
    plan: {
      type: Object,
      default: () => ({
        heaJobStressPlanId: '',  // 직무스트레스 계획 일련번호
        plantCd: null,  // 사업장
        planYear: '',  // 년도
        jobStressPlanName: '',  // 평가계획명
        assessmentStartDate: '',  // 평가 시작일
        assessmentEndDate: '',  // 평가 종료일
        period: [], // 평가기간
        remarks: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        users: [], // 평가자
        centers: [], // 중앙값
        depts: [], // 중앙값
        imprs: [], // 중앙값
        resultTable: [],
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      data: [],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
      },
      grid2: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '요청내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
      },
      imprData: null,
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
    };
  },
  computed: {
    gridHeight() {
      let _height = this.contentHeight - 500;
      if (_height <= 300) {
        _height = 300
      }
      return String(_height) + 'px';
    },
    imprs() {
      return this.plan.imprs && this.plan.imprs.length > 0
      ? this.$_.filter(this.plan.imprs, { ibmClassCd: 'IC00000001' }) : []
    },
    imprImms() {
      return this.plan.imprs && this.plan.imprs.length > 0
      ? this.$_.filter(this.plan.imprs, { ibmClassCd: 'IC00000005' }) : []
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.jobStress.impr.list.url
      // code setting
      // list setting
    },
    getImprs() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        heaJobStressPlanId: this.popupParam.heaJobStressPlanId,
      }
      this.$http.request((_result) => {
        this.$set(this.plan, 'imprs', _result.data)
      },);
    },
    addImprImm() {
      this.popupOptions.title = 'LBLIMMEDIATE'; // 즉시조치
      this.popupOptions.param = {
        requestContents: this.plan.jobStressPlanName,
        relationTableKey: this.popupParam.heaJobStressPlanId,
        ibmTaskTypeCd: 'ITT0000185',
        ibmTaskUnderTypeCd: 'ITTU000215',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    addImpr() {
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: this.plan.jobStressPlanName,
        relationTableKey: this.popupParam.heaJobStressPlanId,
        ibmTaskTypeCd: 'ITT0000185',
        ibmTaskUnderTypeCd: 'ITTU000215',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImprs();
    },
    /* eslint-disable no-unused-vars */
    immlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '즉시조치 상세'; // 즉시조치 상세
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImmDetailPopup;
    },
    closeImmDetailPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          this.$_.extend(this.imprData, result.col1)
        } else {
          this.data = this.$_.reject(this.data, { sopImprovementId: result.col1.sopImprovementId })
        }
      }
    },
    imprlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.width = '85%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    closeImprDetailPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          this.$_.extend(this.imprData, result.col1)
        } else {
          this.data = this.$_.reject(this.data, { sopImprovementId: result.col1.sopImprovementId })
        }
      }
    },
  }
};
</script>
